/* website: 4122-timminshonda1

 * created at 2023-07-11 17:10 by mfaye
 */

// Import all makes and organization styles files
@import "../templates/makes/honda.scss";
@import "../utils/icons.scss";

.widget-sr{
  &.dealer__timminshonda{
    .instant-estimate__pattern{
      background-repeat: no-repeat !important;
      background-position-y: center !important;
      background-position-x: 20px !important;
    }
  }
}